/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/


html {
    margin: 0;
    padding: 0;
}

* {
    margin: 0px;
    padding: 0px;
 }

 div#root {
    margin: 0px;
    padding: 0px;
    height: 100%;
   //  border: .5px solid black;
   //  background-color: purple;
 }

//  body {
//     margin: 0px;
//     padding: 0px;
//     background-color: yellow;

//  }

//  body{
//    margin: "0px 0px 0px 0px";
//    padding: "0px 0px 0px 0px";
//    background-color: yellow;

// }
