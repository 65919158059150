// * {
//     margin: auto;
// }

// https://jsfiddle.net/thirtydot/k8gspoyv/

.checkers-app {


    .piece-W {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 8px;

        background: red;
        border: 2px solid gold;
        // color: ;
        text-align: center;

        font: 32px Arial, sans-serif;
    }

    .piece-w {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 8px;

        background: #F00;
        border: 2px solid #F00;
        // color: ;
        text-align: center;

        font: 32px Arial, sans-serif;
    }

    .piece-B {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 8px;

        background: black;
        border: 2px solid gold;
        // color: ;
        text-align: center;

        font: 32px Arial, sans-serif;
    }

    .piece-b {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        padding: 8px;

        background: black;
        border: 2px solid black;
        // color: ;
        text-align: center;

        font: 32px Arial, sans-serif;
    }

    .black-square.clicked-square {
        // filter: alpha(opacity=60);

        background-color: rgba(51, 170, 51, .1);
        // background-color: green
    }

    .white-square.clicked-square {
        background-color: red
    }

    .black-square {
        background-color: #BA7A3A
    }

    .white-square {
        background-color: #F0D2B4
    }



    td {
        text-align: center;
        background-color: #BA7A3A;
        width: 65px;
        height: 65px;
    }

    .red-turn-text {
        text-align: center;
        color: black;
        font-family: "open sans";
        font-size: 55px;
    }

    .black-turn-text {
        text-align: center;
        font-family: "open sans";
        font-size: 55px;
        color: lightgray;
    }

    .black-piece {
        width: 20px;
        height: 20px;
        padding: 12px;
        background-color: black;
        border: 1px solid white;
        border-radius: 50px;
    }

    .red-piece {
        width: 20px;
        height: 20px;
        padding: 12px;
        background-color: red;
        border: 1px solid white;
        border-radius: 50px;
    }

    .king.red-piece {
        background-color: orange;
    }

    .king.black-piece {
        background-color: purple;
    }

    p {
        display: inline-block;
    }

    #divider {
        font-size: 90px;
        margin-left: 15px;
        margin-right: 15px;
    }

    span {
        display: inline-block;
    }

    @media screen and (max-width: 560px) {
        .red-piece {
            height: 4.5vw;
            width: 4.5vw;
        }

        .black-piece {
            height: 4.5vw;
            width: 4.5vw;
        }

        td {
            width: 12vw;
            height: 12vw;
        }

        .red-turn-text {
            font-size: 40px;
        }

        .black-turn-text {
            font-size: 40px;
        }

        table {
            margin: 0;
        }

        .mobile {
            display: block;
        }

        .desktop {
            display: none;
        }

        .red-turn-text {
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
        }
    }

    @media screen and (max-width: 380px) {
        .red-piece {
            height: 3vw;
            width: 3vw;
        }

        .black-piece {
            height: 3vw;
            width: 3vw;
        }
    }

    @media screen and (max-width: 345px) {
        .red-piece {
            height: 2.5vw;
            width: 2.5vw;
        }

        .black-piece {
            height: 2.5vw;
            width: 2.5vw;
        }
    }

    @media screen and (max-width: 300px) {
        .red-piece {
            height: 1.5vw;
            width: 1.5vw;
        }

        .black-piece {
            height: 1.5vw;
            width: 1.5vw;
        }

        .red-turn-text {
            font-size: 35px;
        }

        .black-turn-text {
            font-size: 35px;
        }
    }
}